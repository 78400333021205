import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Icon, SimpleGrid,
  useColorModeValue, Select, Box, Button,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState, useEffect } from "react";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { HashLink } from 'react-router-hash-link';

// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox"
import moment from "moment";
// Assets
import { MdDateRange, MdPayments, MdCancel, MdPerson, MdPerson2, MdPerson4, } from "react-icons/md";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoMdCash } from "react-icons/io";

import { usePostRequest } from "../../../../mutation";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AllService from "../../../../services/index";
import Pagination from "../../../../components/Pagination";
//charts
import PieChart from "components/charts/PieChart";
// import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";

export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [summaryLocation, setSummaryLocation] = useState({});
  const [summaryCountry, setSummaryCountry] = useState({});

  //PIE CHART DATA 
  const [genderData, setGenderData] = useState({})
  const [countryDataArray, setCountryDataArray] = useState([])
  const [locationDataArray, setLocationDataArray] = useState([])
  const [maritalStatusData, setMaritalStatusData] = useState({})

  //get gender data
  const getGender = () => {
    const male = tableData?.filter((item) => item.gender === "Male")
    const female = tableData?.filter((item) => item.gender === "Female")
    const gender = {
      male: male.length,
      female: female.length
    }
    setGenderData(gender)
  }

  //marital Status data
  const getStatus = () => {
    const single = tableData?.filter((item) => item.civil_status === "Single")
    const married = tableData?.filter((item) => item.civil_status === "Married")
    const divorced = tableData?.filter((item) => item.civil_status === "Divorced")
    const widow = tableData?.filter((item) => item.civil_status === "Widow")
    const status = {
      single: single.length,
      married: married.length,
      divorced: divorced.length,
      widow: widow.length
    }
    setMaritalStatusData(status)
  }

  useEffect(() => {
    getGender()
    getStatus()
  }, [tableData]);



  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = tableInstance;
  initialState.pageSize = 20;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  //the alert component
  const cancelRef = useRef();


  //SUMMARY BY COUNTRY
  useEffect(() => {
    if (selectedCountry === null) {
      const summaryFilter = {
        male: 0,
        female: 0,
      }
      setCountryDataArray([])
      setSummaryCountry(summaryFilter)
    } else {
      const selectedValid = async () => {
        const selected = await tableData?.filter((item) => item.country === selectedCountry)
        const male = selected?.filter((item) => item.gender === "Male")
        const female = selected?.filter((item) => item.gender === "Female")
        const summaryFilter = {
          male: male.length,
          female: female.length,
        }
        setCountryDataArray(selected)
        setSummaryCountry(summaryFilter)
      }
      selectedValid()
    }

  }, [selectedCountry])



  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 4 }}
        gap="20px"
        mb="20px"
        mt="40px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdPerson} color={brandColor} />
              }
            />
          }
          name="Total Registered Users"
          value={tableData.length || 0}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdPerson2} color={"green.700"} />
              }
            />
          }
          name="Male Users"
          value={genderData.male || 0}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdPerson4} color={"red.700"} />
              }
            />
          }
          name="Female Users"
          value={genderData.female || 0}
          url="#"
        />
      </SimpleGrid>

      {/* Users BY COUNTRY AND CHRUCH ENDS */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            All Users
          </Text>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" overflowX={"auto"}>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "firstname") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "lastname") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "email") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "gender") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "city") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "country") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>


    </>
  );
}
