import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdLocationOn,
  MdPerson,
  MdLock,
  MdMovie,
  MdPeopleAlt,
  MdHome,
} from "react-icons/md";

import { HiOutlineNewspaper } from "react-icons/hi"
import { FaBook, FaMusic, FaPodcast } from "react-icons/fa"

// Admin Imports
import Users from "views/admin/users";
import Songs from "views/admin/songs";
import Books from "views/admin/books";
import Albums from "views/admin/album";
import MainDashboard from "views/admin/default";
import Podcast from "views/admin/podcast";
import Episode from "views/admin/episodes";


// Auth Imports
import SignInCentered from "views/auth/signIn";
let userRoute = {};

const user = sessionStorage.getItem("user");
if (!user) {
  userRoute = {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  };

}
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Books",
    layout: "/admin",
    icon: (
      <Icon
        as={FaBook}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/books",
    component: Books,
  },
  {
    name: "Albums",
    layout: "/admin",
    icon: (
      <Icon
        as={HiOutlineNewspaper}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/albums",
    component: Albums,
  },


  {
    name: "Songs",
    layout: "/admin",
    icon: (
      <Icon
        as={FaMusic}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/songs",
    component: Songs,
  },
  {
    name: "Podcast",
    layout: "/admin",
    icon: <Icon as={FaPodcast} width="20px" height="20px" color="inherit" />,
    path: "/podcast",
    component: Podcast,
  },
  {
    name: "Episode",
    layout: "/admin",
    icon: <Icon as={MdMovie} width="20px" height="20px" color="inherit" />,
    path: "/episode",
    component: Episode,
  },

  {
    name: "Users",
    layout: "/admin",
    icon: <Icon as={MdPeopleAlt} width="20px" height="20px" color="inherit" />,
    path: "/users",
    component: Users,
  },


  userRoute,
];

export default routes;
