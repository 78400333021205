import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Textarea,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody, FormControl,
  ModalCloseButton, Select, Divider, SimpleGrid, VisuallyHidden,
} from "@chakra-ui/react";
import React, { useMemo, useState, useRef, useEffect } from "react";
// import { NavLink } from "react-router-dom";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from "moment";

// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

// Assets
import { MdInfo, MdDateRange, MdMusicNote } from "react-icons/md";
import Pagination from "../../../../components/Pagination";
import Swal from "sweetalert2";
import AllService from "../../../../services/index";
import { usePostRequestWithFile, usePostRequest } from "../../../../mutation";
import { useForm } from "react-hook-form";

export default function ColumnsTable(props) {
  const { columnsData, tableData, podcasts } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,

    pageCount,
  } = tableInstance;
  initialState.pageSize = 15;


  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  //the alert component
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  //states
  const [songDetails, setSongDetails] = useState([]);
  const [songCover, setSongCover] = useState("initial");
  const [albumDetails, setAlbumDetails] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedPodcastId, setSelectedPodcastId] = useState(null);
  const { createPost, isLoading, errorResp, success } =
    usePostRequestWithFile(`episodes/add`);
  const {
    register,
    handleSubmit, setValue,
    formState: { errors, },
  } = useForm({
    defaultValues: {
      host: 'Onorio Cutane',
    }
  });


  const onSubmit = (data) => {
    let file = data.audio[0];
    let sampleFile = data.sample[0];
    if (file.size > 20242880) {
      Swal.fire({
        title: "Error!",
        text: "Episode File or Episode Sample is larger than 19MB",
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    } else {
      delete data.audio
      delete data.sample
      createPost({ ...data, podcastId: selectedPodcastId, audio: file, sample: sampleFile });
    }
  };


  useEffect(() => {
    if (success) {
      setTimeout(
        Swal.fire({
          title: "New Episode Added!",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#87AA62",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
          backdrop: `
              #87AA62
              left top
              no-repeat
            `,
        }),
        100000
      );
      window.location.reload();
    }
    if (errorResp) {
      console.log(errorResp);
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  //the selected option
  useEffect(() => {
    const audio = tableData.filter((item) => item._id === selectedId)
    setSongDetails(audio[0])
  }, [selectedId])

  //SELECTED ALBUM 
  useEffect(() => {
    const album = podcasts.filter((item) => item._id === selectedPodcastId)
    setAlbumDetails(album[0])
    setSongCover(album[0]?.cover)
  }, [selectedPodcastId])

  // DELETE A SONG
  const deleteEpisode = () => {
    AllService.deleteEpisode(selectedId)
      .then((res) => {
        if (res.data.status === 200) {
          setTimeout(
            Swal.fire({
              title: "Episode Deleted!",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
              backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
            }),
            100000
          );
          window.location.reload();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    onClose();
  };

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
        mt="40px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdMusicNote} color={brandColor} />
              }
            />
          }
          name="Total Episode"
          value={tableData.length}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdDateRange} color={brandColor} />
              }
            />
          }
          name="Today's Date"
          value={moment(tableData[0]?.createdAt).format('LLLL')}
          url="#"
        />
      </SimpleGrid>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Episodes
          </Text>
        </Flex>
        <div style={{ overflowX: "auto" }}> <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" overflowX={{ sm: "scroll", lg: "hidden" }}>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Title") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "description") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "duration") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "price") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "priceId") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "priceIdMobile") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "streamUri") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "more") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">

                          <Button
                            onClick={() => {
                              setSelectedId(cell.value);
                              onOpen();
                            }}
                          >

                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color={"yellow.400"}
                              as={MdInfo}
                            />
                          </Button>
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table></div>

        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>
      {/* Add Episode*/}
      <Card
        direction="column"
        my="60px"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        style={{ padding: "40px 90px", alignContent: "center" }}
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="70px">
            Add Episode
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w="100%"
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(onSubmit)} method="POST"
            encType="multipart/form-data">
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Title<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small className="text-primary-red text-xs" style={{ color: "red" }}>
                  Please input title
                </small>
              )}
              <Input

                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter title ..."
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("title", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Host<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small className="text-primary-red text-xs" style={{ color: "red" }}>
                  Please Enter host name
                </small>
              )}
              <Input

                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Host name ..."
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("host", { required: true })}
              /><FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Description<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small className="text-primary-red text-xs" style={{ color: "red" }}>
                  Please input description
                </small>
              )}
              <Textarea
                style={{ border: "solid 1px grey" }}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                placeholder="Enter the description"
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("description", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Duration<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small className="text-primary-red text-xs" style={{ color: "red" }}>
                  Please input duration
                </small>
              )}
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="number"
                step="0.01"
                placeholder="Enter duration .. 0.00 ..."
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("duration", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Price<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small className="text-primary-red text-xs" style={{ color: "red" }}>
                  Please input price
                </small>
              )}
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="number"
                step="0.01"
                placeholder="Enter price .... 0.00 ."
                mb="12px"
                fontWeight="500"
                size="lg"
                {...register("price", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Select Podcast<Text color={"red"}>*</Text>
              </FormLabel>
              {errors.full_name && (
                <small className="text-primary-red text-xs" style={{ color: "red" }}>
                  Please select Podcast
                </small>
              )}
              <Select placeholder='Select option' variant="auth"
                fontSize="sm"
                mb="12px"
                fontWeight="500"
                size="lg"
                onClick={() =>
                  setValue("podcastImageUrl", songCover)
                }
                onChange={(e) =>
                  setSelectedPodcastId(e.target.value)
                }
              >
                {podcasts.map((item, index) => (
                  <option value={item._id} key={index}>{item.title}</option>
                )
                )}
              </Select>
              {songCover === "initial" ? <></> :
                <>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    <Text size={"xl"} mr="12px">Podcast Image</Text>
                    <img src={songCover} alt="album" height={50} width={50} />
                  </FormLabel>
                  <VisuallyHidden> <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    mb="12px"
                    {...register("podcastImageUrl", { required: true })}
                    isReadOnly={true}
                  /></VisuallyHidden>
                </>}

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                <Text size={"xl"}>Select the Episode Sample (0.30s long)</Text><Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                width="100%"
                ms={{ base: "0px", md: "0px" }}
                type="file"
                mb="12px"
                accept="audio/mp3"
                {...register("sample", { required: true })}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                <Text size={"xl"}>Select the Episode</Text><Text color={"red"}>*</Text>
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                width="50%"
                ms={{ base: "0px", md: "0px" }}
                type="file"
                mb="12px"
                accept="images/*"
                {...register("audio", { required: true })}
              />
              <div>
                <Button
                  mt={20}
                  fontSize="sm"
                  colorScheme="green"
                  fontWeight="500"
                  w="30%"
                  mb="24px"
                  type="submit"
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? "Adding Episode..." : "Add Episode"}
                </Button>
              </div>
            </FormControl>
          </form>
        </Flex>
      </Card>
      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete {songDetails?.title} </ModalHeader>
          <Divider orientation='horizontal' mb={4} />
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize='sm'>You cant edit this content. Deleted content are irreversible</Text>
            <Divider orientation='horizontal' mt={4} mb={4} />

          </ModalBody>
          {/* <Divider orientation='horizontal' mt={4} mb={4} /> */}
          <ModalFooter>
            <hr></hr>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={deleteEpisode} ml={3}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
