
const countries = [
  { symbol: "AF", country: "Afghanistan" },
  { symbol: "AL", country: "Albania" },
  { symbol: "DZ", country: "Algeria" },
  { symbol: "AD", country: "Andorra" },
  { symbol: "AO", country: "Angola" },
  { symbol: "AG", country: "Antigua and Barbuda" },
  { symbol: "AR", country: "Argentina" },
  { symbol: "AM", country: "Armenia" },
  { symbol: "AU", country: "Australia" },
  { symbol: "AT", country: "Austria" },
  { symbol: "AZ", country: "Azerbaijan" },
  { symbol: "BS", country: "Bahamas" },
  { symbol: "BH", country: "Bahrain" },
  { symbol: "BD", country: "Bangladesh" },
  { symbol: "BB", country: "Barbados" },
  { symbol: "BY", country: "Belarus" },
  { symbol: "BE", country: "Belgium" },
  { symbol: "BZ", country: "Belize" },
  { symbol: "BJ", country: "Benin" },
  { symbol: "BT", country: "Bhutan" },
  { symbol: "BO", country: "Bolivia" },
  { symbol: "BA", country: "Bosnia and Herzegovina" },
  { symbol: "BW", country: "Botswana" },
  { symbol: "BR", country: "Brazil" },
  { symbol: "BN", country: "Brunei" },
  { symbol: "BG", country: "Bulgaria" },
  { symbol: "BF", country: "Burkina Faso" },
  { symbol: "BI", country: "Burundi" },
  { symbol: "KH", country: "Cambodia" },
  { symbol: "CM", country: "Cameroon" },
  { symbol: "CA", country: "Canada" },
  { symbol: "CV", country: "Cape Verde" },
  { symbol: "CF", country: "Central African Republic" },
  { symbol: "TD", country: "Chad" },
  { symbol: "CL", country: "Chile" },
  { symbol: "CN", country: "China" },
  { symbol: "CO", country: "Colombia" },
  { symbol: "KM", country: "Comoros" },
  { symbol: "CG", country: "Congo" },
  { symbol: "CD", country: "Democratic Republic of Congo" },
  { symbol: "CR", country: "Costa Rica" },
  { symbol: "CI", country: "Ivory Coast" },
  { symbol: "HR", country: "Croatia" },
  { symbol: "CU", country: "Cuba" },
  { symbol: "CY", country: "Cyprus" },
  { symbol: "CZ", country: "Czech Republic" },
  { symbol: "DK", country: "Denmark" },
  { symbol: "DJ", country: "Djibouti" },
  { symbol: "DM", country: "Dominica" },
  { symbol: "DO", country: "Dominican Republic" },
  { symbol: "EC", country: "Ecuador" },
  { symbol: "EG", country: "Egypt" },
  { symbol: "SV", country: "El Salvador" },
  { symbol: "GQ", country: "Equatorial Guinea" },
  { symbol: "ER", country: "Eritrea" },
  { symbol: "EE", country: "Estonia" },
  { symbol: "ET", country: "Ethiopia" },
  { symbol: "FJ", country: "Fiji" },
  { symbol: "FI", country: "Finland" },
  { symbol: "FR", country: "France" },
  { symbol: "GA", country: "Gabon" },
  { symbol: "GM", country: "Gambia" },
  { symbol: "GE", country: "Georgia" },
  { symbol: "DE", country: "Germany" },
  { symbol: "GH", country: "Ghana" },
  { symbol: "GR", country: "Greece" },
  { symbol: "GD", country: "Grenada" },
  { symbol: "GT", country: "Guatemala" },
  { symbol: "GN", country: "Guinea" },
  { symbol: "GW", country: "Guinea-Bissau" },
  { symbol: "GY", country: "Guyana" },
  { symbol: "HT", country: "Haiti" },
  { symbol: "HN", country: "Honduras" },
  { symbol: "HU", country: "Hungary" },
  { symbol: "IS", country: "Iceland" },
  { symbol: "IN", country: "India" },
  { symbol: "ID", country: "Indonesia" },
  { symbol: "IR", country: "Iran" },
  { symbol: "IQ", country: "Iraq" },
  { symbol: "IE", country: "Ireland" },
  { symbol: "IL", country: "Israel" },
  { symbol: "IT", country: "Italy" },
  { symbol: "JM", country: "Jamaica" },
  { symbol: "JP", country: "Japan" },
  { symbol: "JO", country: "Jordan" },
  { symbol: "KZ", country: "Kazakhstan" },
  { symbol: "KE", country: "Kenya" },
  { symbol: "KI", country: "Kiribati" },
  { symbol: "KW", country: "Kuwait" },
  { symbol: "KG", country: "Kyrgyzstan" },
  { symbol: "LA", country: "Laos" },
  { symbol: "LV", country: "Latvia" },
  { symbol: "LB", country: "Lebanon" },
  { symbol: "LS", country: "Lesotho" },
  { symbol: "LR", country: "Liberia" },
  { symbol: "LY", country: "Libya" },
  { symbol: "LI", country: "Liechtenstein" },
  { symbol: "LT", country: "Lithuania" },
  { symbol: "LU", country: "Luxembourg" },
  { symbol: "MK", country: "North Macedonia" },
  { symbol: "MG", country: "Madagascar" },
  { symbol: "MW", country: "Malawi" },
  { symbol: "MY", country: "Malaysia" },
  { symbol: "MV", country: "Maldives" },
  { symbol: "ML", country: "Mali" },
  { symbol: "MT", country: "Malta" },
  { symbol: "MH", country: "Marshall Islands" },
  { symbol: "MR", country: "Mauritania" },
  { symbol: "MU", country: "Mauritius" },
  { symbol: "MX", country: "Mexico" },
  { symbol: "FM", country: "Micronesia" },
  { symbol: "MD", country: "Moldova" },
  { symbol: "MC", country: "Monaco" },
  { symbol: "MN", country: "Mongolia" },
  { symbol: "ME", country: "Montenegro" },
  { symbol: "MA", country: "Morocco" },
  { symbol: "MZ", country: "Mozambique" },
  { symbol: "MM", country: "Myanmar" },
  { symbol: "NA", country: "Namibia" },
  { symbol: "NR", country: "Nauru" },
  { symbol: "NP", country: "Nepal" },
  { symbol: "NL", country: "Netherlands" },
  { symbol: "NZ", country: "New Zealand" },
  { symbol: "NI", country: "Nicaragua" },
  { symbol: "NE", country: "Niger" },
  { symbol: "NG", country: "Nigeria" },
  { symbol: "KP", country: "North Korea" },
  { symbol: "NO", country: "Norway" },
  { symbol: "OM", country: "Oman" },
  { symbol: "PK", country: "Pakistan" },
  { symbol: "PW", country: "Palau" },
  { symbol: "PA", country: "Panama" },
  { symbol: "PG", country: "Papua New Guinea" },
  { symbol: "PY", country: "Paraguay" },
  { symbol: "PE", country: "Peru" },
  { symbol: "PH", country: "Philippines" },
  { symbol: "PL", country: "Poland" },
  { symbol: "PT", country: "Portugal" },
  { symbol: "QA", country: "Qatar" },
  { symbol: "RO", country: "Romania" },
  { symbol: "RU", country: "Russia" },
  { symbol: "RW", country: "Rwanda" },
  { symbol: "KN", country: "Saint Kitts and Nevis" },
  { symbol: "LC", country: "Saint Lucia" },
  { symbol: "VC", country: "Saint Vincent and the Grenadines" },
  { symbol: "WS", country: "Samoa" },
  { symbol: "SM", country: "San Marino" },
  { symbol: "ST", country: "Sao Tome and Principe" },
  { symbol: "SA", country: "Saudi Arabia" },
  { symbol: "SN", country: "Senegal" },
  { symbol: "RS", country: "Serbia" },
  { symbol: "SC", country: "Seychelles" },
  { symbol: "SL", country: "Sierra Leone" },
  { symbol: "SG", country: "Singapore" },
  { symbol: "SK", country: "Slovakia" },
  { symbol: "SI", country: "Slovenia" },
  { symbol: "SB", country: "Solomon Islands" },
  { symbol: "SO", country: "Somalia" },
  { symbol: "ZA", country: "South Africa" },
  { symbol: "KR", country: "South Korea" },
  { symbol: "SS", country: "South Sudan" },
  { symbol: "ES", country: "Spain" },
  { symbol: "LK", country: "Sri Lanka" },
  { symbol: "SD", country: "Sudan" },
  { symbol: "SR", country: "Suriname" },
  { symbol: "SE", country: "Sweden" },
  { symbol: "CH", country: "Switzerland" },
  { symbol: "SY", country: "Syria" },
  { symbol: "TW", country: "Taiwan" },
  { symbol: "TJ", country: "Tajikistan" },
  { symbol: "TZ", country: "Tanzania" },
  { symbol: "TH", country: "Thailand" },
  { symbol: "TG", country: "Togo" },
  { symbol: "TO", country: "Tonga" },
  { symbol: "TT", country: "Trinidad and Tobago" },
  { symbol: "TN", country: "Tunisia" },
  { symbol: "TR", country: "Turkey" },
  { symbol: "TM", country: "Turkmenistan" },
  { symbol: "TV", country: "Tuvalu" },
  { symbol: "UG", country: "Uganda" },
  { symbol: "UA", country: "Ukraine" },
  { symbol: "AE", country: "United Arab Emirates" },
  { symbol: "GB", country: "United Kingdom" },
  { symbol: "US", country: "United States" },
  { symbol: "UY", country: "Uruguay" },
  { symbol: "UZ", country: "Uzbekistan" },
  { symbol: "VU", country: "Vanuatu" },
  { symbol: "VA", country: "Vatican City" },
  { symbol: "VE", country: "Venezuela" },
  { symbol: "VN", country: "Vietnam" },
  { symbol: "YE", country: "Yemen" },
  { symbol: "ZM", country: "Zambia" },
  { symbol: "ZW", country: "Zimbabwe" },
];

export default countries;
