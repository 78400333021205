import http from "./http-common";
let token = sessionStorage.getItem("authToken");


//SONGS
const deleteSong = (data) => {
  return http.delete(`music/remove/${data}`);
};
//BOOK
const deleteBook = (data) => {
  return http.delete(`/books/remove/${data}`);
};
//ALBUM
const deleteAlbum = (data) => {
  return http.delete(`/albums/remove/${data}`);
};
const getAllLeaders = (page = 1, limit = 10) => {
  return http.get("/api/churchleader");
};



const getAllTransaction = () => {
  return http.get(`api/admin/transaction`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const deletePodcast = (data) => {
  return http.delete(`podcasts/remove/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const deleteEpisode = (data) => {
  return http.delete(`episodes/remove/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const getPodcast = (data) => {
  return http.get(`podcasts/find/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const getAllEpisode = (data) => {
  return http.get(`episodes/all`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const updatePodcast = (data, id) => {
  return http.put(`podcasts/update/${id}`, data);
};
const getAllPodcast = (data) => {
  return http.get(`podcasts/all`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const getDashBoardData = () => {
  return http.get("users/summary", {
    headers: {
      Authorization: `${token}`,
    },
  });
};



const logout = (data) => {
  return http.delete(`api/admin/logout`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const exportedObject = {
  deleteBook,
  deleteSong,
  deleteAlbum,
  logout,
  getAllLeaders,
  getAllTransaction,
  getAllEpisode,
  deletePodcast,
  getPodcast,
  getAllPodcast,
  updatePodcast,
  getDashBoardData,
  deleteEpisode
};

export default exportedObject;
